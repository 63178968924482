import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '..';
import { isUserAdmin } from '../utils';
import Unauthorized from './Unauthorized';

export default function AdminRoute(props) {
  // const user = useSelector(state => state.impersonationReducer.impersonation?.isImpersonating ? state.impersonationReducer.impersonation : state.AWSReducer.user)
  const trueUser = useSelector(state => state.AWSReducer.user)
  const isAdmin = isUserAdmin(trueUser)
  return isAdmin ? props.element : <Layout><Unauthorized /></Layout>
}