import React, {Component} from 'react';
import Widget from '../common/Widget';
import {Link, Typography, Alert, AlertTitle, Skeleton} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faThumbsUp,
    faFaceMonocle,
    faCalendar,
    faBinoculars,
    faSquareExclamation,
    faDisplay
} from '@fortawesome/pro-light-svg-icons';
import {getData} from '../../DataAccessLayer';
import {systemStatus} from '../../DataAccessLayer/services';
import {
    SYSTEM_STATUS_FIXED,
    SYSTEM_STATUS_IDENTIFIED
} from '../common/constants';
import {getDateTime} from '../common/utils';
import PortalTooltip from '../common/PortalTooltip';
import ApiErrorMessage from '../common/ApiErrorMessage';

class SystemStatus extends Component {
    state = {
        isExpand: false,
        isLoading: false,
        systemStatus: [],
        status: SYSTEM_STATUS_FIXED,
        isError: false
    };

    getSeverity = status => {
        let severity = '';
        switch (status) {
            case 'fixed':
                severity = 'success';
                break;
            case 'watching':
            case 'investigating':
            case 'identified':
                severity = 'error';
                break;
            default:
                severity = 'info';
        }
        return severity;
    };

    getIcon = status => {
        let icon = '';
        switch (status) {
            case 'fixed':
                icon = faThumbsUp;
                break;
            case 'identified':
                icon = faSquareExclamation;
                break;
            case 'investigating':
                icon = faFaceMonocle;
                break;
            case 'scheduled':
                icon = faCalendar;
                break;
            case 'watching':
                icon = faBinoculars;
                break;
            default:
                icon = faDisplay;
        }
        return icon;
    };

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    toggleIsError = value => {
        this.setState({isError: value});
    };

    loadSystemStatus = () => {
        this.toggleIsError(false);
        this.setState({isLoading: true});
        getData(systemStatus, true)
            .then(result => {
                this.setState({
                    servicesDown: result.servicesDown,
                    status: result.servicesDown?.length
                        ? SYSTEM_STATUS_IDENTIFIED
                        : SYSTEM_STATUS_FIXED
                });
            })
            .catch(err => {
                this.toggleIsError(true);
                console.log(err);
            })
            .finally(() => this.setState({isLoading: false}));
    };

    getServicesDownText = () => {
        return this.state.servicesDown.reduce(
            (service, value, i, array) =>
                service + (i < array.length - 1 ? ', ' : ' and ') + value
        );
    };

    componentDidMount() {
        this.loadSystemStatus();
    }

    render() {
        return (
            <div id={"systemStatus__div_wrapper"}>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title,
                        isTitleStylized: this.props.isTitleStylized,
                        isExpand: this.props.widget.isExpand,
                        isRequired: this.props.widget.isRequired
                    }}
                    isExpand={this.state.isExpand}
                    className="myOdu__systemStatus"
                    {...this.props}
                >
                    <div id={"systemStatus__div_statusAlert"} className="wrapper">
                        {this.state.isLoading && (
                            <Skeleton id={"systemStatus__skeleton"} variant="rectangular" height={80} />
                        )}
                        {!this.state.isLoading && this.state.isError && (
                            <ApiErrorMessage
                                id={"systemStatus__apiErrorMessage"}
                                widgetName={this.props.title}
                                reload={this.loadSystemStatus}
                            />
                        )}
                        {!this.state.isLoading && !this.state.isError && (
                            <div id={"systemStatus__div_statusAlertWrapper"}>
                                <Alert
                                    id={"systemStatus__alert_statusAlert"}
                                    variant="outlined"
                                    severity={this.getSeverity( this.state.status )}
                                    className="alert"
                                    icon={
                                        <FontAwesomeIcon
                                            id={"systemStatus__icon_" + this.state.status}
                                            icon={this.getIcon( this.state.status )}
                                            fixedWidth
                                            size="lg"
                                        />
                                    }
                                >
                                    <AlertTitle id={"systemStatus__alert_alertTitle"} sx={{mb: 0}}>
                                        <PortalTooltip
                                            id={'systemStatus__tooltip'}
                                        >
                                            <Typography component="h3">
                                                <Link
                                                    id="systemStatus__link_systemPage"
                                                    href="https://status.odu.edu/"
                                                    target="_blank"
                                                    underline="hover"
                                                >
                                                    {this.state.servicesDown?.length
                                                        ? `Issues reported with ${this.getServicesDownText()}`
                                                        : 'All Systems Operational'}
                                                </Link>
                                            </Typography>
                                        </PortalTooltip>
                                    </AlertTitle>

                                    <Typography
                                        id={"systemStatus__text_currentTime"}
                                        component="p"
                                        variant="smallest"
                                        sx={{fontStyle: 'italic'}}
                                    >
                                        {getDateTime()}
                                    </Typography>
                                </Alert>
                            </div>
                        )}
                    </div>
                </Widget>
            </div>
        );
    }
}

export default SystemStatus;
