const {Time} = require('./MomentTime');
const {DayMapping} = require('./constants');

module.exports = {
    getDateTime: date => {
        return Time.University(date).format('MMMM D, YYYY, h:mm A');
    },

    // Redirects the user to old portal
    stopPreview: href => {
        // delete the preview cookie
        document.cookie =
            'portal-preview=; Path=/; Domain=.odu.edu; Expires=Thu, 01 Jan 1970 23:59:59 GMT';
        // navigate to old portal
        window.location.href = href;
    },

    //Θ(N) where N is the number of space separated tokens in a string
    //clean up the id string by converting to lower case, remove any special chars except '_' and trim
    cleanUpID: id => {
        if (!id) return '';

        return (
            id
                ?.toLocaleLowerCase()
                ?.trim()
                ?.replace(/[^a-zA-Z0-9_ ]/g, '')
                ?.split(/\s/g)
                ?.map((token, index) =>
                    index
                        ? (token?.[0]?.toLocaleUpperCase() ?? '') +
                          (token?.slice(1) ?? '')
                        : token
                )
                ?.join('') ?? ''
        );
    },

    //Θ(NxM) where N is the number of nested objects and M is the number of attributes in each nested object
    //Deep copies an object to avoid problems with referenced data
    deepCloneObject: object => {
        return Object.keys(object).reduce((clone, current) => {
            clone[current] =
                object[current].constructor === Object
                    ? module.exports.deepCloneObject(object[current])
                    : object[current];
            return clone;
        }, {});
    },

    // Convert days code (ex. MWF) to full name (ex. Monday, Wednesday and Friday)
    convertDayCodesToFullNames: dayCodes => {
        const fullNames = Array.from(dayCodes).map(
            code => DayMapping[code] || code
        );

        if (fullNames.length > 1) {
            const lastDay = fullNames.pop();
            return `${fullNames.join(', ')} and ${lastDay}`;
        }

        return fullNames.join(', ');
    },

    //Swaps the array elements at index1 & index2
    swap: (array, index1, index2) => {
        let temp = array[index1];
        array[index1] = array[index2];
        array[index2] = temp;

        return array;
    }
};
