import React, {Component} from 'react';
import Widget from '../common/Widget';
// import {ResourceLinks} from './config';
import {Link, List, ListItem, Tooltip} from '@mui/material';
import PortalTooltip from '../common/PortalTooltip';

class Resources extends Component {
    state = {
        isExpand: true,
        //TODO: enable in v1.1
        // menu:[
        //     {id: "Resources_hide", title: "Hide"},
        // ]
    };

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    render() {
        return (
            <>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title, 
                        isTitleStylized: this.props.isTitleStylized,
                        isExpand: this.props.widget.isExpand,
                        isRequired: this.props.widget.isRequired
                    }}
                    {...this.props}
                    className="myOdu__resources"
                    hasScrollY
                >
                    <div className="wrapper" id={this.props.widget.id + '__resources__listWrapper'}>
                        <List sx={{pt: 0}} dense={true} id={this.props.widget.id + "__resources__list"}>
                            {this.props.resourceLinks.map(resource => (
                                <ListItem
                                    id={this.props.widget.id + "__resources__listItem_" + resource.id}
                                    sx={{pl: 1}}
                                    key={this.props.widget.id + '__resources__listItem_' + resource.id }
                                    divider
                                >
                                    <PortalTooltip id={this.props.widget.id +'__resources__tooltip_' + resource.id} >
                                        <Link
                                            id={this.props.widget.id + '__resources__link_' + resource.id}
                                            variant="small"
                                            href={resource.link}
                                            underline={'hover'}
                                            target="_blank"
                                        >
                                            {resource.title}
                                        </Link>
                                    </PortalTooltip>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Widget>
            </>
        );
    }
}

export default Resources;
