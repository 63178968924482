import React, {Component} from 'react';
import {connect} from 'react-redux';
// import {
// RouterProvider,
// } from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {Box, IconButton, Menu, Toolbar} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import Navigation from './Navigation';
import Impersonation from '../Impersonation';
import PortalToast from '../common/PortalToast';
import PortalTooltip from '../common/PortalTooltip';
import SwapBanner from '../common/SwapBanner';
// import router from './Router';
import {getUser, isUserAdmin} from './utils';
import ImpersonationBanner from '../common/ImpersonationBanner';
import WithRouter from './Router/WithRouter';
import Branding from './Navigation/Branding';
import SideNavToggler from './Navigation/Toggler/SideNavToggler';
import PortalAppBar from './Header/PortalAppBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import {WithSmallScreen} from '../common/WithSmallScreen';
import { savePreferences } from '../SetUp/actions';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import { putData } from '../../DataAccessLayer';
import { myPreferences } from '../../DataAccessLayer/services';
import { DEFAULT_DISPLAY_OPTIONS } from '../common/constants';

const drawerWidth = 240;

const openedMixin = theme => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = theme => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '75px'
});

const Main = styled('main', {shouldForwardProp: prop => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        // marginLeft: `calc(-${drawerWidth} + 40px)`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }),
            marginLeft: '0'
        }),
        overflowX: 'hidden'
    })
);

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    //   justifyContent: 'flex-end',
    marginTop: open ? '0' : '3rem'
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== 'open'})(
    ({theme, open}) => ({
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme)
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme)
        })
    })
);

class Layout extends Component {
    state = {
        isMobileMenuOpen: false,
        anchor: false,
    };

    //θ(1) Toggles the isSidebar stored in preferences
    toggleSidebar = (isSidebar = !this.props.preferences.DisplayOptions.isSidebar) => {
        let preferences = this.props.preferences;
        preferences.DisplayOptions.isSidebar = isSidebar
        this.props.savePreferences(preferences);
        this.updatePreferences(preferences);
    };

    //θ(1) Updates the preferences on the backend
    updatePreferences = (preferences = this.props.preferences) => {
        const user = getUser(this.props.user, this.props.impersonation);
        putData(myPreferences, {
            preferences,
            midas: user.midas
        }, true)
        .catch(err => console.log(err))
    }

    toggleMobileMenu = () => {
        this.setState({isMobileMenuOpen: !this.state.isMobileMenuOpen});
    };   

    isMobile = () => {
        return this.props.isSmall;
    };

    //θ(1) Returns the isSidebar boolean
    getIsSidebar = () => {
        const preferences = this.props.preferences

        //Check if isSidebar is present inside DisplayOptions. If not then add it and set to default value
        //Using hasOwnProperty as isSidebar is a boolean and we dont want to trigger it when isSidebar is false, as it indicates that the sidebar is collapsed
        if(!preferences?.DisplayOptions?.hasOwnProperty('isSidebar')) {
            return DEFAULT_DISPLAY_OPTIONS.isSidebar
        }
        return preferences.DisplayOptions.isSidebar;
    }

    render() {
        const isAdmin = isUserAdmin(this.props.user);
        const isSidebar = this.getIsSidebar();
        return (
            <>
                {/* render above the page contents */}
                {this.props.isImpersonating && <ImpersonationBanner />}

                {/* impersonation modal */}
                <Impersonation />

                <Box
                    sx={{display: 'flex'}}
                    className={
                        'myOdu__appContentWrapper ' +
                        (this.props.isImpersonating ? 'impersonating' : ' ')
                    }
                >
                    {/* technically rendered for mobile, but class in component hides it */}
                    <SideNavToggler
                        onToggle={this.toggleSidebar}
                        isMenuOpen={isSidebar}
                    />

                    {/* mobile menu in here */}
                    <PortalAppBar 
                        isAdmin={isAdmin} 
                        isSidebarOpen={this.state.isMobileMenuOpen} 
                        onToggle={this.toggleMobileMenu} 
                        onClickNavigation={this.toggleMobileMenu} 
                        isImpersonating={this.props.isImpersonating}
                        isMobile={this.isMobile()}
                    />

                    <Drawer
                        ModalProps={{disableScrollLock: false}}
                        variant="permanent"
                        open={isSidebar}
                        className="myOdu__sideNav"
                        sx={{overflowY: 'scroll'}}
                        id="sideNav__drawer"
                    >
                        <DrawerHeader>
                            <Branding
                                isPositionTopNav={false}
                                isMenuOpen={isSidebar}
                                isCrownVisible={true}
                            />
                        </DrawerHeader>
                        <Navigation data={{isOpen: isSidebar}} />
                    </Drawer>

                    <Main
                        open={isSidebar}
                        className={(isSidebar ? 'sidebarOpen' : 'sidebarClosed') + (this.props.isImpersonating ? 'impersonating ' : '') }
                    >
                        <PortalToast />
                        <DrawerHeader />
                        {this.props.children}
                        {/* <RouterProvider router={router} /> */}
                    </Main>
                </Box>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.AWSReducer.user,
        isImpersonating:
            state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation,
        preferences: state.preferencesReducer.preferences
    };
};

const mapDispatchToProps = dispatch => ({
    toggleImpersonation: () => dispatch(toggleImpersonation()),
    savePreferences: preferences => dispatch(savePreferences(preferences))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithRouter(WithSmallScreen(Layout)));
