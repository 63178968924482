import React, {Component} from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    IconButton,
    Typography,
    Box,
    Menu,
    MenuItem
} from '@mui/material';
import {
    faChevronDown,
    faEllipsisVertical,
    faGripDots
} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import WidgetMenu from './WidgetMenu';
import { connect } from 'react-redux';
import { savePreferences } from '../SetUp/actions';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import { deepCloneObject } from './utils';
import { toggleWidgetSelection } from '../SetUp/utils';

class Widget extends Component {

    state = {
    }

    componentDidMount() {
    }

    //θ(N) wher N is the number of widgets
    //Updates the isExpand value of the widget and triggers a throttle to save preferences via api.
    updatePreferences = () => {
        let preferences = deepClone(this.props.preferences);

        let widgetsOrder = preferences?.Widgets?.WidgetsOrder;

        //Loop over widgets order to find the widget whose isExpand status needs to be changed
        widgetsOrder = widgetsOrder?.map(section => {
            return section.map(widget => {
                if(widget.id === this.props.data.id) {
                    widget.isExpand = !widget.isExpand;
                }
                return widget;
            })
        });

        preferences.Widgets.WidgetsOrder = widgetsOrder;

        //Trigger the throttle
        this.props.triggerPreferenceThrottle();

        //Save Preferences in redux
        this.props.savePreferences(preferences);

        this.props.updateWidgets(preferences);
    }

    //θ(N) where N is the number of widgets in configs
    //Sets the isDisplay of the widget to hise as false
    onHideWidget = (widgetId) => {
        let preferences = deepClone(this.props.preferences);
        
        const widgetsOrder = toggleWidgetSelection(preferences.Widgets.WidgetsOrder, widgetId);
        preferences.Widgets.WidgetsOrder = widgetsOrder;

        //Save Preferences in redux
        this.props.savePreferences(preferences);

        //Call Update widgets to update the widgets in dashboard state
        this.props.updateWidgets(preferences);
        
        //Trigger the throttle
        this.props.triggerPreferenceThrottle();
    }

    writeTitle = () => {
       if(this.props.data.isTitleStylized){
        return <Stack direction="row" className="myOdu__stylizedText">
          
            {this.props?.data?.title?.map((word, index) => (
                 <Typography component="span" id={this.props.data.id + "_text_titleWord" + index} key={this.props.data.id + "_title_word_" + index}>{word}</Typography>
            ))}
            
            </Stack>
       }
       else return this.props.data.title;
    }

    isAccordionExpanded = () => {
        return this.props.data.isExpand;
    }

    render() {
        return (
            <React.Fragment>
                <Accordion
                    expanded={this.isAccordionExpanded()}
                    elevation={0}
                    className={'myOdu__widget ' + (this.props.className || '')}
                    id={this.props.data.id + '__accordion'}
                >
                    {/* To make it library independent update provided props */}
                    <div  {...this.props.listeners}>
                        <AccordionSummary
                            expandIcon={
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    // size="lg"
                                    onClick={() => this.updatePreferences()}
                                    fixedWidth
                                    id={this.props.data.id + "__chevron_accordionSummary"}
                                />
                            }
                            id={this.props.data.id + '__accordionSummary'}
                            aria-controls={
                                this.props.data.id + '__accordionDetails'
                            }
                            sx={{flexDirection: 'row-reverse', flexGrow: 1}}
                            style={{cursor: this.props.isDragging ? 'grabbing' : 'pointer', zIndex: this.props.isDragging ? 2000 : 100}}
                            className={"header"}
                            justifyContent="center"
                        >
                            <Typography
                                id={this.props.data.id +"__title" }
                                component="h2"
                                // variant="h4"
                                onClick={() => this.updatePreferences()}
                                className="myOdu__widgetTitle"
                            >
                                {this.writeTitle()}
                            </Typography>
                            <Box
                                id={ this.props.data.id+ "__box" }
                                sx={{flexGrow: 1}}
                                onClick={() => this.updatePreferences()}
                            />
                            {(this.props.data.menu || !this.props.data.isRequired) && (
                                <Box className="widgetMenuWrapper" id={ this.props.data.id + "__widgetMenu_wrapper" }>
                                    <WidgetMenu
                                        data={{
                                            title: this.props.data.title,
                                            id: this.props.data.id,
                                            isMenuDisabled: this.props.data.isMenuDisabled,
                                            isRequired: this.props.data.isRequired,
                                        }}
                                        menu={this.props.data.menu}
                                        onHideWidget= {this.onHideWidget}
                                    />
                                </Box>
                            )}
                        </AccordionSummary>
                    </div>
                    <AccordionDetails
                        id={this.props.data.id + '__accordionDetails'}
                        className={"myOdu__widgetDetails " 
                            + (this.props.hasScrollY ? ' scrollY' :'')
                            + (this.props.hasScrollX ? ' scrollX' :'')
                        }
                    >
                        {this.props.children}
                    </AccordionDetails>
                </Accordion>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        preferences: state.preferencesReducer.preferences,
        user: state.AWSReducer.user,
        isImpersonating: state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    savePreferences: (preferences) => dispatch(savePreferences(preferences))
});
export default connect(mapStateToProps, mapDispatchToProps)(Widget);
