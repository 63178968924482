import React from 'react';
import {defaultAnimateLayoutChanges, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import ApplicationCard from '../common/ApplicationCard';
import { ICON_PATH } from '../common/constants';
import WidgetCard from './WidgetCard';

function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props.id,
    data: {
      section: props.sectionNumber,
      widget: props.widget
    },
    transition: {
      duration: 400,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
    },
    disabled: props.disabled
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };
  
  return (
    <div ref={setNodeRef} style={{...style}} {...attributes}>
       {/* <div style={{height: '100%', opacity: props.isDragging && props.app.id === props.selectedApp.id ? '0%' : '100%'}} className={props.isDragging && "wiggle"}> */}
            <WidgetCard widget = {props.widget} listeners = {listeners} isDragging = {isDragging} triggerPreferenceThrottle = {props.triggerPreferenceThrottle} updateWidgets = {props.updateWidgets} />
        {/* </div> */}
    </div>
  );
}

export default SortableItem;