export const SAVE_PREFERENCES = 'SAVE_PREFERENCES';
export const savePreferences = (preferences, isOutOfSync = false) => ({
    type: SAVE_PREFERENCES,
    payload: {preferences, isOutOfSync}
});

export const SAVE_IS_OUT_OF_SYNC = 'SAVE_IS_OUT_OF_SYNC';
export const saveIsOutOfSync = (isOutOfSync) => ({
    type: SAVE_IS_OUT_OF_SYNC,
    payload: {isOutOfSync}
});

export const SAVE_SETUP = 'SAVE_SETUP';
export const saveSetup = (setup) => ({
    type: SAVE_SETUP,
    payload: {setup}
});