import { SAVE_IS_OUT_OF_SYNC, SAVE_PREFERENCES, SAVE_SETUP } from "./actions";

const initialStatePreferences = {
    preferences: {},
    isOutOfSync: false
}

const initialStateSetup = {
    setup: {}
}

//needs to persist across pages
export const preferencesReducer = (state = initialStatePreferences, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAVE_PREFERENCES: {
            return {...state, preferences: {...payload.preferences}, isOutOfSync: payload.isOutOfSync}
        }
        case SAVE_IS_OUT_OF_SYNC: {
            return {...state, isOutOfSync: payload.isOutOfSync}
        }
        default:
            return state;
    }
}

//does not needs to persist across pages
export const setupReducer = (state = initialStateSetup, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAVE_SETUP: {
            return {...state, setup: {...payload.setup}}
        }
        default:
            return state;
    }
}