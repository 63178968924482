import { Box, Button, FormControlLabel, Grid, Menu, MenuItem, Switch, Tab, Tabs, Typography } from '@mui/material';
import React, { Component } from 'react';
import { filterBadData, getSelectedApps } from '../../../MyApps/utils';
import { ICON_PATH } from '../../../common/constants';
import { AppConfigs, WidgetConfigs } from '../../../SetUp/config';
import { connect } from 'react-redux';
import WidgetCard from './WidgetCard';
import { getSelectedAppsCount, isDisplayWidget, toggleAppSelection, toggleWidgetSelection, verifyWidgetRole } from '../../../SetUp/utils';
import { myPreferences } from '../../../../DataAccessLayer/services';
import { getData, putData } from '../../../../DataAccessLayer';
import { faCaretDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUser } from '../../../Layout/utils';
import { savePreferences } from '../../../SetUp/actions';
import { deepCloneObject } from '../../../common/utils';
import Categories from '../../myApps/FavoriteApps/Categories';
import { getWidgets } from '../../../Dashboard/utils';
import { deepClone } from '@mui/x-data-grid/utils/utils';

class FavoriteWidgets extends Component {

    state = {
        activeTab: 1,
        loadingWidgets: {},
    }

    //θ(1) Add/Remove the widgetId from the loadingWidgets object based on the changes being made
    updateLoadingWidgets = (widgetId, isLoading) => {
        if(!widgetId)
            return
        const loadingWidgets = this.state.loadingWidgets;
        if(isLoading) {
            //add widgetId to the object if its toggled and waiting for the backend call to finish
            loadingWidgets[widgetId] = true;
        } else {
            // Remove the widgetId from the object when we receive a response for respective app from bankend.
            delete loadingWidgets[widgetId];
        }

        this.setState({
            loadingWidgets
        })
    }

    //O(N) where N is the number of widgets 
    //Updates the selection of the widget
    onUpdateWidgetSelection = (widget) => {
        //Add widget to the loading object to show the loading symbol on UI. Note will be removed on completion of save api.
        this.updateLoadingWidgets(widget.id, true);
        let preferences = deepClone(this.props.preferences);
        
        //Update the display status of the widget
        const widgetsOrder = toggleWidgetSelection(preferences.Widgets.WidgetsOrder, widget.id);
        preferences.Widgets.WidgetsOrder = widgetsOrder;

        //Make an api call
        this.storePreferences(preferences, widget.id);
    }

    //Θ(1) makes a call to backend to store preferences
    storePreferences = (preferences, widgetId) => {
        putData(myPreferences, {
            preferences,
            midas: getUser(this.props.user, this.props.impersonation).midas
        }, true)
        .then(_ => {
            this.props.savePreferences(preferences);
        })
        .catch(err => console.log(err))
        .finally(_ => {
            this.loadPreferences(widgetId)
        })
    }

    //θ(1) Load the preferences object again from backend
    loadPreferences = (widgetId) => {
        getData(
            myPreferences +
                '/' +
                (getUser(this.props.user, this.props.impersonation).midas),
            true
        )
        .then(preferences => {
            this.props.savePreferences(preferences);
        })
        .catch(err => {
            console.log(err);
        })
        .finally(_ => {
            //Remove the widgetid from the loading object. This removes the loading symbol from the UI
            this.updateLoadingWidgets(widgetId, false)
        })
    }

    //θ(N) where N is the number of widgets
    //Groups widgets based on Required, Recommended and Other
    groupWidgets = (widgetsOrder) => {
        const widgets = {
            requiredWidgets: [],
            recommendedWidgets: [],
            otherWidgets: []
        }

        widgetsOrder = widgetsOrder?.flat();

        const user = getUser(this.props.user, this.props.impersonation);

        widgetsOrder.reduce((widgetsObject, currentWidget) => {
            const isRoleSatisfied = verifyWidgetRole(currentWidget, user);
            //If the widgets is set as required and matches the user's role then we add it as required 
            //We also add it if the widget is required and does not have any role requirement
            if(currentWidget.isRequired && (isRoleSatisfied || !currentWidget.defaultDisplay)) {
                widgetsObject.requiredWidgets.push(currentWidget);
            }
            //If widgets roles matches users roles then its a recommended widget
            else if(isRoleSatisfied) {
                widgetsObject.recommendedWidgets.push(currentWidget);
            }
            //Other widget
            else {
                widgetsObject.otherWidgets.push(currentWidget);
            }
            return widgetsObject;
        }, widgets)

        return widgets;
        
    }

    //θ(N) where N is the number of section
    //Get all the widgets in ordered format with all the details from config file.
    getWidgets = () => {
        let widgetsOrder = this.props.preferences.Widgets.WidgetsOrder;
        widgetsOrder = widgetsOrder.map((section, idx) => {
            return getWidgets(section, idx, true);
        });

        return widgetsOrder;
    }

    render() {
        const widgetsOrder = this.getWidgets();
        const {requiredWidgets, recommendedWidgets, otherWidgets} = this.groupWidgets(widgetsOrder);

        const user = getUser(this.props.user, this.props.impersonation);

        return <React.Fragment>
            <Typography component="h4" className="sr-only visibility-hidden">Show/Hide Widgets</Typography>
            <Grid container direction={'column'} style={{paddingTop: 8}} wrap='nowrap'>
                <Grid item xs={10}>
                    <Box className="favoriteApps">
                        {   
                            requiredWidgets.length ?
                            <Box>
                                <Typography component='h3'>Required</Typography>
                                {
                                    requiredWidgets.map((widget) => {
                                        return <WidgetCard 
                                            widget={widget}
                                            showHeart
                                            isSelected = {widget.isDisplay}
                                            onUpdateWidgetSelection = {this.onUpdateWidgetSelection}
                                            loadingWidgets = {this.state.loadingWidgets}
                                            required
                                        />
                                    })
                                }
                            </Box>
                            : <></>
                        }
                        {
                            recommendedWidgets.length ?
                            <Box>
                                <Typography component='h3' className="mt-5">Recommended</Typography>
                                {
                                    recommendedWidgets.map((widget) => {
                                        return <WidgetCard 
                                            widget={widget}
                                            showHeart
                                            isSelected = {widget.isDisplay}
                                            onUpdateWidgetSelection = {this.onUpdateWidgetSelection}
                                            loadingWidgets = {this.state.loadingWidgets}
                                        />
                                    })
                                }
                            </Box>
                            : <></>
                        }
                        {
                            otherWidgets.length ?
                            <Box>
                                <Typography component='h3' className="mt-5">Other</Typography>
                                {
                                    otherWidgets.map((widget) => {
                                        return <WidgetCard 
                                            widget={widget}
                                            showHeart
                                            isSelected = {widget.isDisplay}
                                            onUpdateWidgetSelection = {this.onUpdateWidgetSelection}
                                            loadingWidgets = {this.state.loadingWidgets}
                                        />
                                    })
                                }
                            </Box>
                            : <></>
                        }
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        preferences: state.preferencesReducer.preferences,
        user: state.AWSReducer.user,
        isImpersonating: state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    saveSetup: (setup) => dispatch(saveSetup(setup)),
    savePreferences: (preferences) => dispatch(savePreferences(preferences))
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteWidgets);