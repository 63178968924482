import React, { Component } from 'react';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import PortalTooltip from '../../../common/PortalTooltip';

class SideNavToggler extends Component {
    render() { 
        return <React.Fragment>             
            <div className="d-none d-sm-block">
                <PortalTooltip title={this.props.isMenuOpen ? "Collapse Menu" : "Expand Menu"}>
                    <IconButton className={"myOdu__sideNavToggler " + (this.props.isMenuOpen ? " " : "closed")} onClick={() => this.props.onToggle(!this.props.isMenuOpen)}>
                        <div className="sr-only visuallyHidden">{this.props.isMenuOpen ? "Collapse Menu" : "Expand Menu"}</div>
                        <FontAwesomeIcon icon={this.props.isMenuOpen ? faCaretLeft : faCaretRight} size="xs" sx={{mr: 1}}/>
                    </IconButton>                
                </PortalTooltip>
            </div>
        </React.Fragment>
    }
}
 
export default SideNavToggler;


