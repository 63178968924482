import { WidgetConfigs } from "../SetUp/config";

//Θ(NxM) where N is the number of widgets in a section and M is the number of widgets in configs
//Returns the ordered lists of widgets which are 1. Fixed (Not draggable) and 2. Draggable
export const getWidgets = (widgetsOrder, sectionIndex, getAllWidgets = false) => {
    let widgets = {
        fixedWidgets: [],
        draggableWidgets: []
    };

    widgets = widgetsOrder.reduce((widgetsObject, currentWidget) => {
        //Find the widget in the Widgets config to check if the widget is draggable
        let widget = WidgetConfigs.Widgets[sectionIndex].find(widget => {
            if(widget.id === currentWidget.id && !widget.isDeleted)
                return widget;
        });

        //If widget is valid and isNoDrag is active then add the widget to the fixedWidget array
        //We only add the widget if either getAllWidgets is passed as true (in case of setting where we show all widgets)
        //OR isDisplay of a widget is true
        if(widget && widget.isNoDrag && (currentWidget?.isDisplay || getAllWidgets)) {
            widgetsObject.fixedWidgets.push({
                ...widget,
                ...currentWidget
            })
        }
        //If widget is valid and isNoDrag is inactive then add the widget to the draggable widgets array 
        //We only add the widget if either getAllWidgets is passed as true (in case of setting where we show all widgets)
        //OR isDisplay of a widget is true
        else if(widget && !widget.isNoDrag && (currentWidget?.isDisplay || getAllWidgets)) {
            widgetsObject.draggableWidgets.push({
                ...widget,
                ...currentWidget
            })
        }
        //If widget is invalid/undefenied then do nothing

        return widgetsObject;
    }, widgets);

    return [...widgets.fixedWidgets, ...widgets.draggableWidgets];
}