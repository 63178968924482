import React from 'react';
import {defaultAnimateLayoutChanges, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import WidgetCard from './WidgetCard';

function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props.id,
    data: {
      section: props.sectionNumber,
      widget: props.widget
    },
    transition: {
      duration: 400,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
    }
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };
  
  return (
    <div ref={setNodeRef} style={{...style}} {...attributes}>
          <WidgetCard 
            widget = {props.widget} 
            isDragging = {isDragging}
            isDNDSelected = {props.isDNDSelected}
            moveWidget = {props.moveWidget}
            hideUp = {props.hideUp} 
            hideDown = {props.hideDown}
            index={props.index}
            sectionNumber = {props.sectionNumber}
            listeners = {{...listeners}}
          />
    </div>
  );
}

export default SortableItem;