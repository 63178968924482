import React, {Component} from 'react';
import Widget from '../common/Widget';
import {UncontrolledTooltip} from 'reactstrap';
import {
    Typography,
    Stack,
    Box,
    Link,
    Grid,
    Divider,
    Tooltip
} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faPhone} from '@fortawesome/pro-light-svg-icons';
import PortalTooltip from '../common/PortalTooltip';

class itsHelp extends Component {
    state = {
        isExpand: true,
        //TODO: enable in v1.1
        // menu:[
        //     {id: "itsHelp_hide", title: "Hide"},
        // ],
        helpDeskTicketCount: 3 //TODO: remove when API wired in
        // quickTip: "Set your password equal to your MIDAS ID to make it easy to remember!",
    };

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    render() {
        return (
            <>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title, 
                        isTitleStylized: this.props.isTitleStylized, 
                        isExpand: this.props.widget.isExpand,
                        isRequired: this.props.widget.isRequired
                    }}
                    {...this.props}
                    className="myOdu__itsHelp"
                >

                    <Stack alignItems="center" justifyContent="center" sx={{textAlign: 'center'}} className="wrapper">
                        
                        <Box sx={{ height: '40px', width: '40px', margin: '0 auto' }} className="myOdu__logoZoom" >
                            <img src="/img/itsHelp.svg" alt="computer with ODU logo on screen" />
                        </Box>
                           
                        <Typography component="h3" variant="h6">
                            Help Available 24/7
                        </Typography>

                        <Stack
                            direction={{
                                xs: 'column',
                                md: 'column',
                                sm: 'row',
                                lg: 'column',
                                xl: 'column',
                                xxl: 'col',
                                xxxl: 'column',
                                xxxxl: 'row'
                            }}

                            sx={{width: '100%'}}
                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{borderColor: 'black'}}
                                    role="presentation"
                                />
                            }
                            spacing={0}
                            justifyContent="space-around"
                            className="text-center"
                        >
                            <PortalTooltip>
                                <Link
                                    id="itsHelp__link_helpOnline"
                                    sx={{mx: 1}}
                                    href="http://www.odu.edu/its/helpdesk"
                                    variant="small"
                                    underline="hover"
                                >
                                    ITS Help Online
                                </Link>
                            </PortalTooltip>

                            <PortalTooltip title="Email ITS Help">
                                <Link
                                    id="itsHelp__link_email_itsHelp"
                                    sx={{mx: 1}}
                                    href="mailto:itsHelp@odu.edu"
                                    variant="small"
                                    underline="hover"
                                >
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        style={{marginRight: '.25rem'}}
                                    />
                                    itsHelp@odu.edu
                                </Link>
                            </PortalTooltip>

                            <PortalTooltip title="Call ITS Help" > 
                                <Link
                                    id="itsHelp__link_phone_757-683-3192"
                                    sx={{mx: 1}}
                                    href="tel:757-683-3192"
                                    variant="small"
                                    underline="hover"
                                >
                                    <FontAwesomeIcon icon={faPhone} style={{marginRight: '.25rem'}} />
                                    757-683-3192
                                </Link>
                            </PortalTooltip>
                        </Stack>
                    </Stack>


                    {/* <Grid item xs={12} sm={8} md={12} lg={12} xl={12}> */}
                    {/* <Box className="bt-1 bt-sm-0 bt-xs-0 pt-md-3" sx={{mb:2}}>
                        <Link href="http://www.odu.edu/its/helpdesk" target="_blank" underline="none" className={"cursor-pointer"} title="Opens Help Desk in new window">
                            <Box sx={{height: '30px', width: '40px', margin: '0 auto'}}  className='myOdu__logoZoom'>
                                <img src="/img/ticket.svg" alt="ticket" />
                            </Box>
                        </Link>
                        <Stack direction="row" alignItems="center" justifyContent="center">
                            <Typography component="p" variant="small">You have </Typography>
                            <Typography sx={{fontWeight: 700, mx:"0.25rem"}}>
                                { this.state.helpDeskTicketCount ?? 'no'} open</Typography>
                            <Link href="http://www.odu.edu/its/helpdesk" target="_blank" underline='always' title="Opens in new window">
                                help desk tickets
                            </Link>
                        </Stack>
                    </Box> */}

                    {/* {this.state.quickTip && 
                        <>
                            <Box className="bt-1" sx={{mt:2}}></Box>
                            <Box className="quickTip" sx={{p:1, mb:2, textAlign: "center", mt:2}}>
                                <Link component="h3" variant="h6" href="#" underline="hover">Quick Tip</Link>
                                <Typography component="p" variant="smaller">
                                    {this.state.quickTip} 
                                </Typography>
                            </Box>
                        </>
                    } */}
                    {/* </Grid> */}

                </Widget>
            </>
        );
    }
}

export default itsHelp;
