import React, { Component } from "react";
import { Typography } from "@mui/material";
import WidgetCard from "./WidgetCard";
import { DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import { connect } from "react-redux";
import { getWidgetsBasedOnRole } from "../SetUp/utils";
import { getUser } from "../Layout/utils";
import { getWidgets } from "./utils";
import DNDContext from "./DNDContext";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import { DragOverlay } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";

class Section extends Component {
    state ={ 

    }

    render() {
        const widgets = this.props.widgetsOrder.filter(widget => widget.isDisplay);
        return <React.Fragment>
            <div style={ this.props.isDragging ? (this.props.isDroppable ? {opacity: 0.5, cursor: 'no-drop'} : {borderTop: 'solid 2px', borderBottom: 'solid 2px'}) : {}}>
                <DNDContext
                    onDragEnd = {this.props.onDragEnd}
                    onDragStart = {this.props.onDragStart}
                    modifiers = {[restrictToVerticalAxis, restrictToParentElement]}
                >
                    <SortableContext items = {widgets.filter(widget => !widget.isNoDrag).map(widget => widget.id)} strategy={verticalListSortingStrategy}>
                        {
                            widgets.map(widget => {
                                return <SortableItem 
                                    widget = {widget}
                                    id = {widget.id}
                                    sectionNumber = {this.props.sectionNumber}
                                    key = {widget.id}
                                    disabled = {widget.isNoDrag}
                                    triggerPreferenceThrottle = {this.props.triggerPreferenceThrottle}
                                    updateWidgets = {this.props.updateWidgets}
                                />
                            })
                        }
                    </SortableContext>
                </DNDContext>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = state => {
    return {
        // preferences: state.preferencesReducer.preferences,
        user: state.AWSReducer.user,
        isImpersonating:
            state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    };
};

export default connect(mapStateToProps)(Section);