import React, {Component} from 'react';
import Widget from '../common/Widget';
import {Container, Row, Col} from 'reactstrap';
import {
    Link,
    Stack,
    Typography,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Grid,
} from '@mui/material';
import {ApplicationCard} from '../common/ApplicationCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/pro-light-svg-icons';
import { cleanUpID } from '../common/utils';
import PortalTooltip from "../common/PortalTooltip";

class UniversityLibraries extends Component {
    state = {
        iconPath: '/img/logos/',
        locations: [
            {
                title: 'ODU Library Resources',
                isExpand: false,
                apps: [
                    {
                        id: 'universityLibraries_odu_monarchOneSearch',
                        name: 'Monarch OneSearch',
                        icon: 'monarchOneSearch.svg',
                        link: 'https://odu-primo.hosted.exlibrisgroup.com/primo-explore/search?vid=01ODU_NUI&amp;lang=en_US',
                        linkTarget: '_blank'
                    },
                    {
                        id: 'universityLibraries_odu_roomReservations',
                        name: 'Room Reservations',
                        icon: 'roomReservations.svg',
                        link: 'https://www.odu.edu/library/places-spaces/room-reservations',
                        linkTarget: '_blank'
                    },
                    {
                        id: 'universityLibraries_odu_libraryGuides',
                        name: 'Library Guides',
                        icon: 'libraryGuides.svg',
                        link: 'https://guides.lib.odu.edu/',
                        linkTarget: '_blank'
                    },
                    {
                        id: 'universityLibraries_odu_researchHelp',
                        name: 'Research Help',
                        icon: 'researchHelp.svg',
                        link: 'https://www.odu.edu/library/help',
                        linkTarget: '_blank'
                    }
                ],
                libraryLinks: [
                    {
                        id: 'universityLibraries_odu_databases',
                        title: 'Databases',
                        link: 'https://guides.lib.odu.edu/az.php',
                        linkTarget: '_blank'
                    },
                    {
                        id: 'universityLibraries_odu_calendar',
                        title: 'Calendar',
                        link: 'https://www.odu.edu/library/about/news-events-exhibits/calendar',
                        linkTarget: '_blank'
                    },
                    {
                        id: 'universityLibraries_odu_hours',
                        title: 'Hours',
                        link: 'https://odu.libcal.com/hours/',
                        linkTarget: '_blank'
                    },
                    {
                        id: 'universityLibraries_odu_interlibraryLoan',
                        title: 'Interlibrary Loan',
                        link: 'https://ww1.odu.edu/library/services/interlibrary-loan',
                        linkTarget: '_blank'
                    }
                ]
            },
            {
                title: 'Health Sciences Resources at Brickell Library',
                isExpand: true,
                apps: [
                    {
                        id: 'universityLibraries_evms_worldCat',
                        name: 'Worldcat Discovery Search',
                        icon: 'worldcat.svg',
                        link: 'https://evms.on.worldcat.org/discovery',
                        linkTarget: '_blank'
                    },
                    {
                        id: 'universityLibraries_evms_pubmed',
                        name: 'PubMed Search',
                        icon: 'pubmed.svg',
                        link: 'https://www.ncbi.nlm.nih.gov/pubmed?otool=vaeebmlib&holding=vaeebmlib',
                        linkTarget: '_blank'
                    },
                    {
                        id: 'universityLibraries_evms_askALibrarian',
                        name: 'Ask a Librarian',
                        icon: 'askALibrarian.svg',
                        link: 'https://www.evms.edu/library/services/reference/',
                        linkTarget: '_blank'
                    },
                    {
                        id: 'universityLibraries_evms_quicklinks',
                        name: 'Quick Links',
                        icon: 'quicklinks.svg',
                        link: ' https://www.evms.edu/library/quicklinks/',
                        linkTarget: '_blank'
                    }
                ],
                libraryLinks: [
                    {
                        id: 'universityLibraries_evms_databases',
                        title: 'Databases',
                        link: 'https://evms.libguides.com/az.php',
                        linkTarget: '_blank'
                    },
                    {
                        id: 'universityLibraries_evms_researchGuides',
                        title: 'Research Guides',
                        link: 'https://evms.libguides.com/',
                        linkTarget: '_blank'
                    },
                    {
                        id: 'universityLibraries_evms_documentDelivery',
                        title: 'Document Delivery/ILL',
                        link: 'https://www.evms.edu/library/services/circ/ill/',
                        linkTarget: '_blank'
                    },
                    {
                        id: 'universityLibraries_evms_services',
                        title: 'Services',
                        link: 'https://www.evms.edu/library/services',
                        linkTarget: '_blank'
                    }
                ]
            }
        ]
    };

    toggleExpand = locationIdx => {};

    render() {
        return (
            <React.Fragment>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title, 
                        isTitleStylized: this.props.isTitleStylized, 
                        isExpand: this.props.widget.isExpand,
                        isRequired: this.props.widget.isRequired
                    }}
                    className="myOdu__universityLibraries"
                    {...this.props}
                >
                    <Container id={"universityLibraries__container_campusWrapper"} fluid>
                        <Row id={"universityLibraries__row_mainCampusTextWrapper"}>
                            <Col
                                id={"universityLibraries__col_mainCampusTextWrapper"}
                                xxl={8}
                                xl={12}
                                lg={6}
                                md={7}
                                sm={6}
                                xs={12}
                                className="text-start px-0"
                            > 
                                <Typography
                                    id={"universityLibraries__text_mainCampusLinkWrapper"}
                                    component="h3"
                                >
                                    <Link
                                        id="universityLibraries__link_mainCampus"
                                        href="http://www.odu.edu/library"
                                        underline={'hover'}
                                        target="_blank"
                                        
                                    >
                                        University Libraries
                                    </Link>
                                </Typography>
                                <Typography component="h4" id={"universityLibraries__text_mainCampus"} className="d-block">
                                    Main Campus
                                </Typography>
                            </Col>

                            <Col
                                id={"universityLibraries__col_mainCampusMobileNumberWrapper"}
                                xxl={4}
                                xl={12}
                                lg={6}
                                md={5}
                                sm={6}
                                xs={12}
                                className="text-xxl-end text-xl-start text-lg-end text-md-end text-sm-end text-start px-0 "
                            >
                                <Link
                                    id="universityLibraries__link_phone_mainCampus"
                                    href="tel:757-683-4179"
                                    underline={'hover'}
                                    className="myOdu__phone"
                                >
                                    757-683-4179
                                </Link>
                            </Col>
                        </Row>
                        <Row id={"universityLibraries__row_evmsTextWrapper"} className="mt-3">
                            <Col
                                id={"universityLibraries__col_evmsTextWrapper"}
                                xxl={8}
                                xl={12}
                                lg={6}
                                md={7}
                                sm={6}
                                xs={12}
                                className="text-start px-0 "
                            >
                                <Typography
                                    id={"universityLibraries__text_evmsLibrary"}
                                    component="h3"
                                >
                                    <Link
                                        id="universityLibraries__link_evms"
                                        href="https://www.evms.edu/library"
                                        underline={'hover'}
                                        target="_blank"
                                        className=""
                                    >
                                        Brickell Medical Sciences Library{' '}
                                    </Link>
                                </Typography>
                                <Typography id={"universityLibraries__text_evms"} component="h4">
                                    Virginia Health Sciences
                                </Typography>
                            </Col>

                            <Col
                                id={"universityLibraries__col_evmsMobileNumberWrapper"}
                                xxl={4}
                                xl={12}
                                lg={6}
                                md={5}
                                sm={6}
                                xs={12}
                                className="text-xxl-end text-xl-start text-lg-end text-md-end text-sm-end text-start px-0 "
                            >
                                <Link
                                    id="universityLibraries__link_phone_evms"
                                    href="tel:757-446-5851"
                                    underline={'hover'}
                                    className="myOdu__phone"
                                >
                                    757-446-5851
                                </Link>
                            </Col>
                        </Row>
                    </Container>

                    {/* <hr className="myOdu__border"/> */}
                    {this.state.locations.map((location, index) => (
                        <Accordion
                            key={'universityLibraries__odu_accordion_'+ cleanUpID(location.title)}
                            expanded={this.toggleExpand(index)}
                            elevation={0}
                            id={'universityLibraries__odu_accordion_'+ cleanUpID(location.title)}
                            className="myOdu__accordion libraryAccordion"
                            sx={{mt:1}}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <FontAwesomeIcon
                                        id={"universityLibraries__icon_chevronDown_" + cleanUpID(location.title)}
                                        icon={faChevronDown}
                                        onClick={() => this.toggleExpand(index)}
                                        fixedWidth
                                    />
                                }
                                id={'universityLibraries__odu_accordionSummary_' + cleanUpID(location.title)}
                                aria-controls={ this.state.widgetId + '_odu_accordionDetails' }
                                sx={{flexDirection: 'row-reverse', flexGrow: 1}}
                            >
                                <Typography
                                    id={"universityLibraries__text_locationTitle_"+cleanUpID(location.title)}
                                    component="h3"
                                    variant="h6"
                                    onClick={() => this.toggleExpand(index)}
                                    className="myOdu__widget_title"
                                    sx={{ml:1}}
                                >
                                    {location.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                id={'universityLibraries__odu_accordionDetails_'+cleanUpID(location.title)}
                                sx={{p: 1}}
                            >
                                <Grid id={"universityLibraries__gridContainer_appWrapper_" +cleanUpID(location.title)} container spacing={2}>
                                    {location.apps.map(app => (
                                        <Grid
                                            id={'universityLibraries__grid_app_'+ cleanUpID(location.title)+ '_' + app.id}
                                            item
                                            xs={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            xxl={6}
                                            xxxl={6}
                                            xxxxl={3}
                                            key={ 'universityLibraries__grid_app_'+ cleanUpID(location.title)+ '_' + app.id }
                                        >
                                            <ApplicationCard
                                                id={"universityLibraries__applicationCard_" + app.id}
                                                app={app}
                                                data={{ icon: this.state.iconPath + app.icon }}
                                                isActive={true}
                                                showHeart={false}
                                                showInfo={false}
                                                showLinks={true}
                                                key={"universityLibraries__applicationCard_" + app.id}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>

                                <Box
                                    id={'universityLibraries__box_spacer_' +cleanUpID(location.title)}
                                    className="myOdu__box"
                                    sx={{
                                        borderBottom: 0,
                                        borderRight: 0,
                                        borderLeft: 0,
                                        p: 0,
                                        mt: 2
                                    }}
                                />
                                <Stack
                                    id={'universityLibraries__stack_locationLinks_' +cleanUpID(location.title)}
                                    direction={{
                                        md: 'column',
                                        sm: 'column',
                                        lg: 'row',
                                        xl: 'column',
                                        xxl: 'col',
                                        xxxl: 'row'
                                    }}
                                    sx={{width: '100%'}}
                                    divider={
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{borderColor: 'black'}}
                                            role="presentation"
                                        />
                                    }
                                    spacing={0}
                                    justifyContent="space-around"
                                    className="text-center"
                                >
                                    {location.libraryLinks.map(item => (
                                        <PortalTooltip
                                            id={'universityLibraries__tooltip_libraryResourceLink_' + item.id}
                                        >
                                            <Link
                                                id={'universityLibraries__link_libraryResourceLink_' + item.id}
                                                href={item.link}
                                                target="_blank"
                                                underline="hover"
                                                style={{
                                                    marginLeft: 0,
                                                    textAlign: 'center'
                                                }}
                                                key={'universityLibraries__link_libraryResourceLink_' + item.id}
                                            >
                                                {item.title}
                                            </Link>
                                        </PortalTooltip>
                                    ))}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Widget>
            </React.Fragment>
        );
    }
}

export default UniversityLibraries;
