import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { Component } from 'react';
import { BreakpointBoxes } from "../../common/BreakpointBoxes";
import ReorderWidgets from './ReorderWidgets';
import FavoriteWidgets from './FavoriteWidgets';

class Widgets extends Component {

    state = {
        activeTab: 1
    }

    //Θ(1) Sets active tab
    onTabChange = (e, activeTab) => {
        this.setState({activeTab});
    };

    render() {
        return <Box className="wrapper">
            {/* <BreakpointBoxes/> */}
            <Typography component={"h3"} className="sr-only visibility-hidden">Widgets Settings</Typography>
                       
                <Tabs
                    value={this.state.activeTab}
                    onChange={this.onTabChange}
                    aria-label="myApps tab"
                    className="secondaryTabs"
                >
                    <Tab
                        value={1}
                        label = {'Show/Hide Widgets'}
                    />
                    <Tab
                        value={2}
                        label = {'Set Display Layout'}
                    />
                </Tabs>


            <Box className="secondaryTabBox">
                {this.state.activeTab === 1 && <FavoriteWidgets />}
                {this.state.activeTab === 2 && <ReorderWidgets />}
            </Box>
        </Box>
    }
}

export default Widgets;