import React, { Component } from 'react';
import Widget from '../common/Widget';
import {Grid, Button, Typography, IconButton, Box, Stack, Link, Tooltip, Skeleton} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faXmark, faPartyHorn} from '@fortawesome/pro-light-svg-icons';
// import SplitButton from '../common/SplitButton';
import PortalModal from '../common/PortalModal';
import {getData, postData} from '../../DataAccessLayer';
import {dismissNotification, notifications} from '../../DataAccessLayer/services';
import {getDateTime} from '../common/utils';

class Notifications extends Component {
    state = {
        widgetId: 'widget__notifications',
        title: "Notifications",
        isExpand: true,
        isLoading: true,
        isNotificationModalOpen: false,
        active: 0,
        // isTruncated: false,
        emptyMessage: 'You are all caught up!',
        notifications: [],
        // button:{
        //     title: "Dismiss",
        //     menu:[
        //         { id: "dismissAll", title: "Dismiss All"}, 
        //         { id: "snooze1Day", title: "Snooze 1 Day"}, 
        //         { id: "snooze1Week", title: "Snooze 1 Week"}
        //     ]
        // }
    }

    //TODO: add code to check for height vs scroll height 
    //https://stackoverflow.com/questions/74201658/how-do-i-truncate-text-and-display-a-read-more-button-in-react-if-the-text-goes
    // isUsingLineClamp = () => {
    //      
    // }

    componentDidMount() {
        this.loadNotifications();
    }

    /**
     * Loads notifications
     */
    loadNotifications = () => {
        this.setState({isLoading: true});
        getData(notifications)
            .then(result => {
                this.setState({notifications: result.notifications ?? []});
            })
            .catch(err => console.log(err))
            .finally(() => this.setState({isLoading: false}));
    };

    /**
     * Dismisses notification
     * @param {string} id The ID of the notification to be dismissed
     */
    dismissNotification = id => {
        this.setState({isLoading: true});
        postData(dismissNotification(id))
            .then(result => {
                this.setState({active: 0})
                this.loadNotifications();
            })
            .catch(err => {
                console.log(err);
                this.setState({isLoading: false})
            });
    };

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand})
    }

    seen = () => {
        let notifications = this.state.notifications.filter((item, index) => index != this.state.active)
        let active = notifications[this.state.active] ? this.state.active : this.state.active - 1
        this.setState({notifications, active})
    }

    moveLeft = () => {
        this.setState({active: this.state.active - 1})
    }

    moveRight = () => {
        this.setState({active: this.state.active + 1})
    }

    toggleNotificationModal = () => {
        this.setState({isNotificationModalOpen: !this.state.isNotificationModalOpen})
    }

    render() {
        const unseen = this.state.notifications.filter(notification => !notification.isSeen)
        return <React.Fragment>

            <PortalModal 
                isOpen={this.state.isNotificationModalOpen} 
                maxWidth="md" 
                id="myOdu__notifications_moreInfoModal"
                onClose={this.toggleNotificationModal}
                noHeader
                isCloseable={true}
                secondaryButtonTitle="Dismiss Notification"
                onSecondaryClick={this.seen}
            >
                <Box sx={{m:2}}>
                    <Typography component="h2" variant="h4">
                        {this.state.notifications[this.state.active]?.TITLE}
                    </Typography>

                    <Typography
                        variant="smallest"
                        component="p"
                        sx={{fontStyle: 'italic', lineHeight: '1.2', mb:1}}
                    >
                        {getDateTime(this.state.notifications[this.state.active]?.START_DATE_TIME)}
                    </Typography>
                                
                    <Typography 
                        id="notificationBody"
                        component="p" 
                        variant="small" 
                        sx={{mt:2}}
                        dangerouslySetInnerHTML={{__html: this.state.notifications[this.state.active]?.BODY}} 
                    />
                </Box>
            </PortalModal>

            <Widget data={{title: this.state.title, id: this.state.widgetId, isExpand: this.props.widget.isExpand}} {...this.props}>
                <div className="myOdu__notifications">
                    {this.state.isLoading && <Skeleton variant='rectangular' height={98} />}
                    {!this.state.isLoading && <Stack direction="row" spacing={0} sx={{mb:0}} className="align-items-center">
                        <Box className="navButton prev">
                            <IconButton id="notifications__button_prev" disabled={this.state.active <= 0} onClick={this.moveLeft} size="small" sx={{borderRadius: '0'}} aria-label='Previous Notification' className="myOdu__iconButton" >
                                <FontAwesomeIcon fixedWidth icon={faChevronLeft} size="lg"/>
                            </IconButton>
                        </Box>

                        {/* //Message at least needs a body */}
                        {this.state.notifications[this.state.active]?.BODY  ? 
                        <Box className="myOdu__box" sx={{m:0, width: '100%'}}>
                            <Stack direction="row">
                                <Box sx={{width: '100%'}}>
                                    {this.state.notifications[this.state.active]?.TITLE && 
                                        <Typography component="h3" variant="h6" sx={{}}>
                                            {this.state.notifications[this.state.active]?.TITLE}
                                        </Typography>
                                    }
                                    {
                                        <Typography
                                            variant="smallest"
                                            component="p"
                                            sx={{fontStyle: 'italic', lineHeight: '1.2', mb:1}}
                                        >
                                            {getDateTime(this.state.notifications[this.state.active]?.START_DATE_TIME)}
                                        </Typography>
                                    }
                                    {this.state.notifications[this.state.active]?.BODY && 
                                    <>
                                        <Typography 
                                            component="p" 
                                            variant="smaller" 
                                            className="myOdu__lineClamp-3"
                                            dangerouslySetInnerHTML={{__html: this.state.notifications[this.state.active]?.BODY}} 
                                            id={this.state.notifications[this.state.active].ID}
                                        />
                                        <Link id="notifications__link_moreInfo" sx={{cursor: 'pointer'}} variant="smaller" underline="always" onClick={this.toggleNotificationModal}>more info</Link>

                                        {/* <Stack direction="row" justifyContent={"space-between"}> */}
                                        <Stack sx={{width: '100%'}} direction={{xs:'col', sm:'row'}} alignItems={'center'} justifyContent={"flex-end"}>
                                            
                                            <Button id="notifications__button_dismiss" className="myOdu__button secondary notificationsButton" variant="outlined" size="sm" onClick={() => {this.dismissNotification(this.state.notifications[this.state.active]?.ID)}} sx={{mt:1}}>Dismiss</Button>
                                        </Stack>
                                     </>
                                    }
                                </Box>
                                {/* <Box>
                                    <Tooltip title="Dismiss this message" enterDelay={750} leaveDelay={0} enterNextDelay={750} arrow>
                                        <IconButton className="xButton" onClick={this.seen}>
                                            <FontAwesomeIcon icon={faXmark} size="xs"/>
                                        </IconButton>
                                    </Tooltip>
                                </Box> */}
                            </Stack>
                        </Box>
                        : 
                        <Box className="myOdu__box" sx={{m:0, width: '100%'}}>
                            <>
                                <FontAwesomeIcon icon={faPartyHorn} fixedWidth size="xl" />
                                <Typography component="p" sx={{ml: 1, display: 'inline'}}>    
                                    {this.state.emptyMessage}    
                                </Typography>
                            </>
                        </Box>
                        }
                        <Box className="navButton next">
                            <IconButton id="notifications__button_next"  disabled={this.state.active >= this.state.notifications.length-1} onClick={this.moveRight} size="small" sx={{borderRadius: '0'}} aria-label="Next Notification" className="myOdu__iconButton" >
                                <FontAwesomeIcon fixedWidth icon={faChevronRight} size="lg"/>
                            </IconButton>
                        </Box>
                    </Stack>}
                </div>
            </Widget>
        </React.Fragment>
    }
}
 
export default Notifications;
