import React from 'react';
import {DndContext, KeyboardSensor, MouseSensor, PointerSensor, TouchSensor, useDraggable, useSensor, useSensors} from '@dnd-kit/core';
import { auto } from '@popperjs/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';

function DNDContext(props) {
  return (
    <DndContext 
        onDragStart={props.onDragStart}
        onDragMove={props.onDragMove}
        onDragOver={props.onDragOver}
        onDragEnd={props.onDragEnd}
        modifiers={props.modifiers}
        sensors={useSensors(
            useSensor(TouchSensor,{
                activationConstraint: {
                    delay: 200,
                    tolerance: 0
                }
            }),
            useSensor(MouseSensor,{
                activationConstraint: {
                    distance: 5,
                    delay: 150,
                    tolerance: 0
                }
            })
        )}
    >
      {props.children}
    </DndContext>
  );
}

export default DNDContext;