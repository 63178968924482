import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { Component } from 'react';
import FavoriteApps from './FavoriteApps';
import ReorderApps from './ReorderApps';
import { BreakpointBoxes } from "../../common/BreakpointBoxes";

class MyApps extends Component {

    state = {
        activeTab: 1
    }

    //Θ(1) Sets active tab
    onTabChange = (e, activeTab) => {
        this.setState({activeTab});
    };

    render() {
        return <Box className="wrapper">
            <Typography component={"h3"} className="sr-only visibility-hidden">myApps Settings</Typography>
                       
                <Tabs
                    value={this.state.activeTab}
                    onChange={this.onTabChange}
                    aria-label="myApps tab"
                    className="secondaryTabs"
                >
                    <Tab
                        value={1}
                        label = {'Choose Favorites'}
                    />
                    <Tab
                        value={2}
                        label = {'Set Display Order'}
                    />
                </Tabs>


            <Box className="secondaryTabBox">
                {this.state.activeTab === 1 && <FavoriteApps />}
                {this.state.activeTab === 2 && <ReorderApps />}
            </Box>
        </Box>
    }
}

export default MyApps;