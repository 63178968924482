import { faArrowDown, faArrowUp, faCircleArrowDown, faCircleArrowUp, faCircleQuestion } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Grid, IconButton, Typography, Box } from "@mui/material";
import React, {Component} from "react";
import { Col, Row } from "reactstrap";
import { MOVE_WIDGET_DOWN, MOVE_WIDGET_UP } from "../../../common/constants";
import PortalTooltip from "../../../common/PortalTooltip";
import { isMobile } from "react-device-detect";
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';


class WidgetCard extends Component {
    state ={

    }

    //θ(1) Returns the tooltip title for move arrows on widget card
    getTitle = (direction, position) => {
        return "Move " + this.props.widget.title + " " + direction + " to position " + position;
    }

    //θ(1) Returns the tooltip title for drag handles on widget card
    getDragTitle = (widget) => {
        //DOnt show on mobile
        if(isMobile) {
            return ""
        }

        //If widget is not allowed to be dragged
        if(widget.isNoDrag) {
            return this.props?.widget?.title + " widget cannot be moved."
        }

        return "Hold to move " + this.props.widget?.title
    }

    render() {
        return <React.Fragment>
            <Card sx={{ 
                marginBottom: '0.5rem',
                p:1, 
                height: '100%',
                width: '100%',
                ...(this.props.isDNDSelected ? {pt: 2, pb: 2} : {p: 1})}} 
                className={'myOdu__appCard'} 
                elevation={0}
            >   
                {/* TODO move inline style to scss */}
                <Grid container direction={"row"} wrap="nowrap" style={{...((this.props.hideUp && this.props.hideDown) ? {border: 0, width: 'auto'} : {width:'auto'}), cursor: this.props.isDragging ? 'grabbing' : 'grab'}} alignItems={'center'}>
                    
                    {!this.props.isDNDSelected &&
                        <Grid item container xs="auto" direction={"row"} wrap="nowrap">
                            <Grid item visibility={this.props.hideUp ? 'hidden' : 'visible'}>
                                <PortalTooltip title={this.getTitle('up', (this.props.index))} enterDelay={850} enterNextDelay={850}>
                                    <IconButton sx={{p: 3}} className="myOdu__iconButton widgetUp" id={"settings__button_widgetCard_" + this.props.widget.id + "_up"} onClick={() => this.props.moveWidget(this.props.widget.id, this.props.sectionNumber, MOVE_WIDGET_UP)} style={this.props.hideUp ? {opacity: 0} : {}} disabled={this.props.hideUp}>
                                        <FontAwesomeIcon icon={faArrowUp} size="sm" />
                                    </IconButton>
                                </PortalTooltip>
                            </Grid>
                            <Grid item visibility={this.props.hideDown ? 'hidden' : 'visible'}>
                                <PortalTooltip title={this.getTitle('down', (this.props.index+2))} enterDelay={850} enterNextDelay={850}>
                                    <IconButton  sx={{p: 3}} className="myOdu__iconButton widgetDown" id={"settings__button_widgetCard_" + this.props.widget.id + "_down"} onClick={() => this.props.moveWidget(this.props.widget.id, this.props.sectionNumber, MOVE_WIDGET_DOWN)} style={this.props.hideDown ? {opacity: 0}: {}} disabled={this.props.hideDown}>
                                        <FontAwesomeIcon icon={faArrowDown} size="sm" />
                                    </IconButton>
                                </PortalTooltip>
                            </Grid>
                        </Grid>
                    }

                    {
                        this.props.isDNDSelected &&
                        <Grid item container xs="auto" direction={"row"} wrap="nowrap">
                            <Grid item {...this.props.listeners}>
                                <PortalTooltip title={this.getDragTitle(this.props.widget)} enterDelay={850} enterNextDelay={850}>
                                    <Box>
                                        <IconButton sx={{p: 3}} className="myOdu__iconButton" id={"settings__button_widgetCard_" + this.props.widget.id + "_grab"} style={{cursor: this.props.isDragging ? 'grabbing' : 'grab'}} disabled={this.props.widget.isNoDrag}>
                                            <FontAwesomeIcon icon={faGripDotsVertical} size="sm" />
                                        </IconButton>
                                    </Box>
                                </PortalTooltip>
                            </Grid>
                        </Grid>
                    }

                    <Grid item sx={{ml:1}} style={{cursor: 'pointer'}} >
                        <Typography variant="h6" component='h4'>
                            {this.props?.widget?.title}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </React.Fragment>
    }
}

export default WidgetCard;