import React, {Component} from 'react';
import Widget from '../common/Widget';
import {
    Button,
    Box,
    Link,
    List,
    ListItem,
    Typography,
    Tooltip
} from '@mui/material';
import {enqueueSnackbar} from 'notistack';
import {deleteData, getData, postData, putData} from '../../DataAccessLayer';
import {myBookmarks} from '../../DataAccessLayer/services';
import BookmarksManager from './BookmarksManager';
import PortalTooltip from '../common/PortalTooltip';
import {toast} from 'react-toastify';

class MyBookmarks extends Component {
    //Θ(1) toggle the bookmarks manager
    onToggle = () => {
        this.setState({isManager: !this.state.isManager});
    };

    state = {
        //control widget expantion
        isExpand: false,

        // Flag to reset the fields after adding/editing the bookmark
        isReset: false,

        //menu for the widget
        menu: [
            {
                id: 'myBookmarks__editBookmarks',
                title: 'Edit Bookmarks',
                onClick: this.onToggle
            }
            //TODO: enable in V1.1
            // {id: "myBookmarks_hide", title: "Hide"},
        ],

        //array of user bookmarks
        bookmarks: [],

        //control bookmarks manager
        isManager: false
    };

    componentDidMount() {
        this.loadBookmarks();
    }

    //Θ(1) load user bookmarks form server
    loadBookmarks = () => {
        getData(myBookmarks).then(bookmarks => {
            if (bookmarks) this.setState({bookmarks});
        });
    };

    //toggle the widget expantion
    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    // Checks if there exists a bookmark already with the same title.
    // Returns `true` if duplicate, else `false`
    isDuplicate = (title, createdAt) => {
        if (!title) return false;
        title = title.toLowerCase();
        return this.state.bookmarks.some(bookmark => {
            let isDupe = bookmark.TITLE.toLowerCase() === title;
            isDupe = createdAt
                ? bookmark.CREATED_AT !== createdAt && isDupe
                : isDupe;
            return isDupe;
        });
    };

    //Θ(1) Add a new bookmark for the user and reload
    onAdd = (title, url) => {
        if (this.isDuplicate(title)) {
            toast.error(
                'Could not add bookmark because the names must be unique. Rename bookmark and try again.'
            );
        } else {
            const body = {
                bookmark: {
                    TITLE: title,
                    URL: url
                }
            };
            putData(myBookmarks, body, true)
                .then(result => {
                    this.setState({isReset: true});
                    this.loadBookmarks();
                    toast.success('Bookmark added');
                })
                .catch(err =>
                    toast.error(
                        "Oops! We can't add the bookmark. Try again in a bit."
                    )
                );
        }
    };

    toggleIsReset = () => {
        this.setState({isReset: !this.state.isReset});
    };

    //Θ(1) Edit the selected bookmark and reload
    onEdit = (title, url, CREATED_AT) => {
        if (this.isDuplicate(title, CREATED_AT)) {
            toast.error(
                'Could not rename bookmark because the names must be unique. Rename bookmark and try again.'
            );
        } else {
            const body = {
                bookmark: {
                    TITLE: title,
                    URL: url,
                    CREATED_AT
                }
            };
            postData(myBookmarks, body, true)
                .then(result => {
                    this.setState({isReset: true});
                    this.loadBookmarks();
                    toast.success('Bookmark changes saved');
                })
                .catch(err =>
                    toast.error(
                        "Oops! We can't update the bookmark. Try again in a bit."
                    )
                );
        }
    };

    //Θ(1) delete the selected bookmark and reload
    onDelete = CREATED_AT => {
        deleteData(myBookmarks + '/' + CREATED_AT, true)
            .then(result => {
                this.setState({isReset: true});
                this.loadBookmarks();
                toast.success('Bookmark deleted');
            })
            .catch(err =>
                toast.error(
                    "Oops! We can't delete the bookmark. Try again in a bit."
                )
            );
    };

    render() {
        return (
            <>
                {/* Let the AddBookmark unmount to reset internal state */}
                {this.state.isManager && (
                    <BookmarksManager
                        id={'myBookmarks__bookmarksManager'}
                        isOpen={this.state.isManager}
                        bookmarks={this.state.bookmarks}
                        onToggle={this.onToggle}
                        onAdd={this.onAdd}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        isReset={this.state.isReset}
                        toggleIsReset={this.toggleIsReset}
                        isDuplicate={this.isDuplicate}
                    />
                )}

                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title,
                        isTitleStylized: this.props.isTitleStylized,
                        menu: this.state.menu,
                        isExpand: this.props.widget.isExpand,
                        isRequired: this.props.widget.isRequired
                    }}
                    isExpand={this.state.isExpand}
                    className="myOdu__myBookmarks"
                    hasScrollY
                    {...this.props}
                >
                    <div className="scrollWrapper">
                        <div
                            className="wrapper"
                            id={'myBookmarks__div_listWrapper'}
                        >
                            <List
                                id={'myBookmarks__list_listItem'}
                                sx={{py: 0}}
                                dense={true}
                            >
                                {this.state.bookmarks.map(bookmark => (
                                    <ListItem
                                        id={
                                            'myBookmarks__listItem_' +
                                            bookmark.CREATED_AT
                                        }
                                        sx={{pl: 1}}
                                        key={
                                            this.state.widgetId +
                                            '_ul_li_' +
                                            bookmark.CREATED_AT
                                        }
                                        divider
                                    >
                                        <PortalTooltip
                                            id={
                                                'myBookmarks__portalTooltip_listItem_' +
                                                bookmark.CREATED_AT
                                            }
                                        >
                                            <Link
                                                id={
                                                    'myBookmarks__link_' +
                                                    bookmark.CREATED_AT
                                                }
                                                variant="small"
                                                href={bookmark.URL}
                                                underline={'hover'}
                                                target="_blank"
                                            >
                                                {bookmark.TITLE}
                                            </Link>
                                        </PortalTooltip>
                                    </ListItem>
                                ))}
                                {this.state.bookmarks.length == 0 && (
                                    <Box className="noBookmarksFound">
                                        <Typography
                                            className="p"
                                            id={
                                                'myBookmarks__cardText_noBookmarksFound'
                                            }
                                        >
                                            No bookmarks found.{' '}
                                        </Typography>
                                        <PortalTooltip
                                            id={
                                                'myBookmarks__portalTooltip_noBookmarksFoundAddBookmarks'
                                            }
                                            title="Open bookmark editor"
                                        >
                                            <Link
                                                id="myBookmarks__link_noBookmarksFoundAddBookmarks"
                                                onClick={this.onToggle}
                                            >
                                                Add bookmarks.
                                            </Link>
                                        </PortalTooltip>
                                    </Box>
                                )}
                            </List>
                        </div>
                    </div>
                </Widget>
            </>
        );
    }
}

export default MyBookmarks;
