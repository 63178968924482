import React, {Component} from 'react';
import Widget from '../common/Widget';
import {Box, Divider, Link, Stack, Typography, Grid} from '@mui/material';
import {Col, Row} from 'reactstrap';
import {connect} from 'react-redux';
import {getData} from '../../DataAccessLayer';
import {myTransferHistory} from '../../DataAccessLayer/services';
import Credits from './Boxes/Credits';
import GpaAcademicStanding from './Boxes/GpaAcademicStanding';
import ProgramResources from './Boxes/ProgramResources';
import myProgramLinks from './config';
import Major from './Boxes/Major';
 
class MyPrograms extends Component {
    state = {
        totalTransferredCredits: 0,
        isDisplayGPA: false,
        currentSem: {},
        nextSem: {}
    };

    componentDidMount() {
        getData(myTransferHistory).then(result => {
            this.loadTransferCredits(result[0]?.PREVIOUS_INSTITUTES);
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.coursesTaking !== this.props.coursesTaking) {
            this.getSemesters();
        }
    }

    //O(N) where N is length of string previousInstitues
    //Retrieves transfer credits from previousInstitues and sums it up
    loadTransferCredits(previousInstitues) {
        if (!previousInstitues) {
            return;
        }
        let totalTransferredCredits = previousInstitues
            .split('|')
            .reduce(
                (total, institute) =>
                    total +
                    Number(
                        institute
                            .substring(
                                institute.lastIndexOf('(') + 1,
                                institute.lastIndexOf(')')
                            )
                            .trim()
                    ),
                0
            );
        this.setState({
            totalTransferredCredits
        });
    }

    toggleIsDisplayGPA = () => {
        this.setState({
            isDisplayGPA: !this.state.isDisplayGPA
        });
    };

    //O(N) where N is length of coursesTaking
    //Retrieves current and next semesters from coursesTaking
    getSemesters = () => {
        
        let {coursesTaking} = this.props;
        const terms = this.getTerms();

        if (coursesTaking.length) {
            let currentSem =
                coursesTaking?.find(
                    el => el.ACADEMIC_PERIOD_5 === terms.CURRENT_TERM
                ) || coursesTaking[0];
            let nextSem =
                coursesTaking?.find(
                    el => el.ACADEMIC_PERIOD_5 === terms.NEXT_TERM
                ) || {};
            this.setState({
                currentSem,
                nextSem
            });
        }
    };

    //θ(N) where N is the number of terms (usually 3)
    //Return the terms object in the form {TERM_TYPE: TERM_CODE}
    getTerms = () => {
        return this.props.terms.reduce((termsObject, term) => {
            termsObject[term.relativeTerm] = term.code;
            return termsObject;
        }, {})
    }

    render() {
        //TODO: Add Updated time
        return (
            <Widget
                data={{
                    id: this.props.widget.id,
                    title: this.props.title,
                    isTitleStylized: this.props.isTitleStylized,
                    isExpand: this.props.widget.isExpand,
                    isRequired: this.props.widget.isRequired
                }}
                className="myOdu__myPrograms"
                hasScrollX={true}
                {...this.props}
            >
                <div id={this.props.widget.id + "__div_wrapper"} className="scrollWrapper">
                    <Stack id={this.props.widget.id + "__stack"}
                        className=""
                        wrap="nowrap"
                        direction="row"
                        rowSpacing={2}
                        alignItems={"stretch"}
                        alignContent={"stretch"}
                    >
                        <Stack id={this.props.widget.id + "__stack_currentProgram"} alignContent={"stretch"} alignItems={"stretch"} sx={{height: '100%'}} className="currentProgram">      
                            <Typography component="h3">Current Program</Typography>
                            <Stack direction="row" className="myOdu__box border currentProgramBox" 
                                divider={<Divider id={'myPrograms__line_divider'} orientation="vertical" flexItem sx={{borderColor: 'black'}} role="presentation" /> }
                            >
                                <Major id="" currentSem={this.state.currentSem} /> 

                                <GpaAcademicStanding
                                    id=" "
                                    currentSem={this.state.currentSem}
                                    isDisplayGPA={this.state.isDisplayGPA}
                                    toggleIsDisplayGPA={this.toggleIsDisplayGPA}
                                />
                               
                                <Credits
                                    id=" "
                                    currentSem={this.state.currentSem}
                                    totalTransferredCredits={this.state.totalTransferredCredits}
                                    terms={this.getTerms()}
                                    nextSem={this.props.nextSem}
                                />
                                                        
                            </Stack>
                        </Stack>

                        <Stack alignContent={"stretch"} alignItems={"stretch"} sx={{ml:1, height: '100%'}}>
                            <Typography component="h3" variant="h6">Program Resources</Typography>
                            <Stack direction="row" className="myOdu__box border programResourcesBox" sx={{width: '100%', p:1, m:0, height: '100%'}} 
                                divider={<Divider id={'myPrograms__line_divider'} orientation="vertical" flexItem sx={{borderColor: 'black'}} role="presentation" /> }
                            >
                                <ProgramResources myProgramLinks={myProgramLinks} />
                            </Stack>                            
                        </Stack>
                    </Stack>
                </div>
            </Widget>
        );
    }
}

const mapStateToProps = state => {
    return {
        coursesTaking: state.myCoursesReducer.coursesTaking,
        terms: state.termsReducer.terms
    };
};

export default connect(mapStateToProps)(MyPrograms);
