import React, {useEffect, useState} from 'react';
import Widget from '../common/Widget';
import {
    Typography,
    Box,
    Skeleton,
    Grid,
    Divider,
    Link,
    Stack
} from '@mui/material';
import {getData} from '../../DataAccessLayer';
import {myWaitlist} from '../../DataAccessLayer/services';
import ApiErrorMessage from '../common/ApiErrorMessage';
import {LEO_REGISTRATION, WAITLIST_TUTORIAL} from '../common/constants';
import {PositionNotice} from './PostionNotice';
import {convertDayCodesToFullNames} from '../common/utils';
import PortalTooltip from '../common/PortalTooltip';

export const MyWaitlist = (props) => {
    const {widget, title, isTitleStylized} = props
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        loadWaitlist();
    }, []);

    const loadWaitlist = async () => {
        setIsLoading(true);
        setIsError(false);
        try {
            const data = await getData(myWaitlist, true);
            setCourses(data.sort((a, b) => a.POSITION - b.POSITION));
        } catch (err) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const displayedCourses = courses.slice(0, 6);
    const areCoursesEven = displayedCourses.length % 2 === 0;

    return (
        <Widget
            data={{
                id: widget.id,
                title,
                isTitleStylized,
                menu: null,
                isRequired: widget.isRequired
            }}
            {...props}
            className="myOdu__myWaitlist"
        >
            {isLoading ? (
                <Skeleton height={98} variant="rectangular" />
            ) : courses.length === 0 && !isLoading ? (
                isError ? (
                    <ApiErrorMessage widgetName={title} reload={loadWaitlist} />
                ) : (
                    <Typography
                        id={widget.id + '__type_nowaitlistFound'}
                        className="noWaitlistFound"
                    >
                        No waitlist found.
                    </Typography>
                )
            ) : (
                <Grid container spacing={1} padding={1} className="container">
                    {displayedCourses.map((course, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={areCoursesEven ? 6 : 12}
                            xl={12}
                            className={
                                areCoursesEven && 'grid-2xl grid-3xl grid-4xl'
                            }
                            key={index}
                        >
                            <Box className="box">
                                <Stack className={!areCoursesEven && 'shrink'}>
                                    <Box>
                                        <Typography className="textRow title">
                                            {course.SUBJ +
                                                ' ' +
                                                course.NUMB +
                                                ' - ' +
                                                course.COURSE_TITLE}
                                        </Typography>
                                        <Typography className="textRow subtitle">
                                            CRN - {course.CRN}
                                        </Typography>
                                        <Typography className="textRow subtitle">
                                            {course.MEETING_DAYS
                                                ? convertDayCodesToFullNames(
                                                      course.MEETING_DAYS
                                                  )
                                                : 'Online'}
                                        </Typography>

                                        <Typography className="textRow subtitle">
                                            {!/^ - $/.test(course.TIMES) ? (
                                                course.TIMES
                                            ) : (
                                                <br></br>
                                            )}
                                        </Typography>
                                    </Box>
                                    <PositionNotice course={course} />
                                </Stack>
                            </Box>
                        </Grid>
                    ))}
                    <Grid item xs={12} className="resources">
                        <Divider orientation="horizontal" className="divider" />
                        <Stack
                            className="resourcesLinks"
                            divider={
                                <Divider
                                    orientation="vertical"
                                    className="divider"
                                    flexItem
                                />
                            }
                        >
                            <Box className="resourceLinkRight">
                                <PortalTooltip title="Waitlist Tutorial">
                                    <Link
                                        href={WAITLIST_TUTORIAL}
                                        target="_blank"
                                        underline={'hover'}
                                    >
                                        Waitlist Tutorial
                                    </Link>
                                </PortalTooltip>
                            </Box>
                            <Box className="resourceLinkLeft">
                                <PortalTooltip title="LeoOnline Registration">
                                    <Link
                                        href={LEO_REGISTRATION}
                                        target="_blank"
                                        underline={'hover'}
                                    >
                                        LeoOnline Registration
                                    </Link>
                                </PortalTooltip>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            )}
        </Widget>
    );
};
