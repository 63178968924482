import './App.css';
import React, {Component} from 'react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import theme from './theme';
import {identifyUser} from './AWS';
import {connect} from 'react-redux';
import {saveUser} from './AWS/actions';
import {saveIsOutOfSync, savePreferences, saveSetup} from './components/SetUp/actions';
import {storePreferences} from './components/SetUp';
import {getData} from './DataAccessLayer';
import {myPreferences} from './DataAccessLayer/services';
import PortalSnackbarProvider from './components/common/PortalSnackbarProvider';
import {Hub} from 'aws-amplify';
import {RouterProvider} from 'react-router-dom';
import {
    isSameAppsVersion,
    isSameWidgetsVersion,
    updateApps,
    updateWidgetsOrder
} from './components/SetUp/utils';
import GoogleAnalytics from './GoogleAnalytics';
import {saveImpersonation} from './components/Impersonation/actions';
import {getUser, isUserAdmin} from './components/Layout/utils';
import router from './components/Layout/Router';
import PortalPageLoader from './components/common/PortalPageLoader';
import {LicenseInfo} from '@mui/x-license-pro';
import CognitoError from './components/Landing/CognitoError';
import { DEFAULT_DISPLAY_OPTIONS } from './components/common/constants';

class App extends Component {
    state = {
        showAuthTestPanel: true,
        showImpersonationPanel: true,
        isLoading: true,
        eventListener: null
    };

    componentDidMount() {
        this.identifyUser().then(() => {
            this.loadUserPreferences();
        });
        this.checkImpersonation();
        GoogleAnalytics();
        this.activateMUILicense();

        // Subscribe to listen to the events of user sign-in
        this.setState({eventListener: Hub.listen('auth', this.listener)});
    }

    componentWillUnmount() {
        // Unsubscribe to listen to the events of user sign-in
        this.state.eventListener();
    }

    identifyUser = async () => {
        const user = await identifyUser();
        isUserAdmin(user) && console.log(user);
        this.props.saveUser(user);
        this.toggleLoading();
    };

    listener = ({payload: {event, data}}) => {
        switch (event) {
            // Listens to 'customOAuthState' event to receive the path dtails of the page that  the user has requested for before the login. 'data' contains the path details of the page that we should be redirecting the user to immediately after the successful login. Example: 'data' can be '/checklists', '/statusAlerts' etc.
            case 'customOAuthState': {
                window.location = data;
                break;
            }
        }
    };

    //Θ(1) Loads all user prederences from backend
    loadUserPreferences = () => {
        getData(
            myPreferences +
                '/' +
                (getUser(this.props.user, this.props.impersonation).midas)
        )
            .then(result => {
                if (result && result.Widgets && result.Apps) {
                    //Flag to check if there is any update in preferences object
                    let isOutOfSync = false;
                    if (!isSameWidgetsVersion(result?.Widgets)) {
                        const user = getUser(
                            this.props.user,
                            this.props.impersonation
                        );
                        let widgets = updateWidgetsOrder(result?.Widgets, user);
                        result.Widgets = widgets;
                        isOutOfSync = true;
                    }
                    if (!isSameAppsVersion(result?.Apps)) {
                        result.Apps = updateApps(
                            result?.Apps,
                            getUser(this.props.user, this.props.impersonation)
                        );
                        isOutOfSync = true;
                    }
                    //Check is display options is present.
                    if(!result.DisplayOptions) {
                        result.DisplayOptions = DEFAULT_DISPLAY_OPTIONS;
                        isOutOfSync = true;
                    }

                    //If there is any update then save it on backend
                    if(isOutOfSync) {
                        storePreferences(result, this.props);
                    }
                    this.props.savePreferences(result, isOutOfSync);
                } else this.props.savePreferences({});
            })
            .catch(err => {
                console.log(err);
                //TODO: Set to default preferences layout
            });
    };

    checkImpersonation = () => {
        if (!this.props.user?.firstName) this.props.saveImpersonation({});
    };

    toggleLoading = () => {
        this.setState({isLoading: !this.state.isLoading});
    };

    activateMUILicense() {
        LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);
    }

    render() {
        const isLoggedIn = this.props.user?.firstName ? true : false;

        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <PortalSnackbarProvider />

                    {this.state.isLoading ? (
                        <PortalPageLoader />
                    ) : (
                        <React.Fragment>
                            <RouterProvider router={router(isLoggedIn)} />
                        </React.Fragment>
                    )}

                    <CognitoError error={this.props.error} />
                </ThemeProvider>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isSideNav: state.headerReducer.isSideNav,
        user: state.AWSReducer.user,
        showAuthTestPanel: state.showAuthTestPanel,
        isImpersonating:
            state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    };
};

const mapDispatchToProps = dispatch => ({
    saveUser: user => dispatch(saveUser(user)),
    savePreferences: (preferences, isOutOfSync) => dispatch(savePreferences(preferences, isOutOfSync)),
    saveImpersonation: impersonation =>
        dispatch(saveImpersonation(impersonation)),
    saveSetup: setup => dispatch(saveSetup(setup))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
