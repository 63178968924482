import React, { Component } from "react";
import { Typography, Box, Button } from "@mui/material";
import { DataColumns, DataRows } from '../dummyData';
import { Container, Row, Col } from "reactstrap";
import ActionCheckbox from "../../common/ActionCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faSquirrel } from "@fortawesome/pro-light-svg-icons";
import PortalDataGrid from "../../common/PortalDataGrid";

class Crud extends Component {
    state = {
        button:{
            title: "Actions",
            menu:[
                { id: "something1", title: "Something"}, 
                { id: "something2", title: "Something Else"}, 
                { id: "something3", title: "A Third Something"}
            ]
        },
        isModalOpen: true,
    };
   

    handleClick = (event) => {
        console.log("here");
    }

	handleOpen = () => {
        //do other open stuff
		this.toggleIsOpen;
	};

	handleClose = () => {
        //do other close stuff
		this.toggleIsOpen;
	};

    toggleIsOpen = () => {
        this.setState({isModalOpen: !this.state.isModalOpen});
    };

    handlePrimaryClick = () => {
        console.log("primary button clicked");
    }

    handleSecondaryClick = () => {
        console.log("secondary button clicked");
    }


	render() {
		return (
			<React.Fragment>
                {/* <Button onClick={this.handleOpen} variant="outlined">Open Modal</Button> */}

                <Box sx={{ml: 1}}>
                    <Typography component="h2" variant="h4" sx={{color: '#003057'}}>
                        Title Goes Here
                    </Typography>
                    <Typography variant="smaller">Custom Breadcrumb Component > Goes > Here</Typography>
                </Box> 

				<Container className="myOdu__crud_wrapper">
                    <Row className="justify-content-between py-1" className="myOdu__crud_buttonRow">
                        <Col>
                            <ActionCheckbox label="Select Users" id="usersSelectAll1" />
                            <Button variant="outlined" size="small" sx={{ml: 2, background: 'white'}} startIcon={<FontAwesomeIcon icon={faCirclePlus}/>}>
                                Add Person
                            </Button>
                            <Button variant="outlined" onClick={this.handleClick} size="small" sx={{ml: 1, background: 'white'}} startIcon={<FontAwesomeIcon icon={faSquirrel}/>}>
                                Another Button
                            </Button>
                        </Col>
                    </Row>

					<Row className="myOdu__crud_content">
						<Col lg={6} className="px-0 myOdu__crud_lhs border-lg-0 ">
                            <PortalDataGrid 
                                checkboxSelection                
                                sx={{border: 0}}
                                className={this.props.className}
                                rows={DataRows}
                                columns={DataColumns}
                                handleClick = {this.props.onClick}
                            />

						</Col>
						<Col lg={6} className="d-md-none d-lg-block myOdu__crud_rhs">
							<Typography component="h3" variant="h5" sx={{color: '#003057'}}>
								Blah Details
							</Typography>
							form here
						</Col>
					</Row>
				</Container>

                {/* <PortalModal maxWidth="sm" handleClose={this.handleClose} handlePrimaryClick={this.handlePrimaryClick} handleSecondaryClick={this.handleSecondaryClick} open={this.state.isModalOpen} data={{title: 'Test Header', subTitle: 'sub heading test goes here', primaryButtonTitle: "Add to Cart", secondaryButtonTitle: "Secondary Button with very long title to test wrapping and layout design"}} hasCloseX={true}>
                    Content 
                    <Typography variant="h4" component="h3">h3 Some stuff goes here</Typography>    
                    blah<br/>
                    blah<br/>
                    <Typography variant="h5" component="h4">h4 Some stuff goes here</Typography>    
                    blah<br/>
                    blah<br/>
                    <Typography variant="h6" component="h5">h5Some stuff goes here</Typography>    
                    blah<br/>
                    blah<br/>
                </PortalModal> */}


			</React.Fragment>
		);
	}
}

export default Crud;
